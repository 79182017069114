// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Intercom = require("../Intercom.bs.js");
var EventLogs = require("../../EventLogs.bs.js");
var ViewStack = require("../ViewStack.bs.js");
var Text__Jsx3 = require("../../../uikit/reason/helpers/Text__Jsx3.bs.js");
var View__Jsx3 = require("../../../uikit/reason/helpers/View__Jsx3.bs.js");
var Caml_format = require("rescript/lib/js/caml_format.js");
var CurrentUser = require("../CurrentUser/CurrentUser.bs.js");
var Dialog__Jsx3 = require("../../../uikit/reason/molecules/Dialog/Dialog__Jsx3.bs.js");
var Buttons__Jsx3 = require("../../../uikit/reason/atoms/Buttons/Buttons__Jsx3.bs.js");
var Types__Chapter = require("../../chapter/types/Types__Chapter.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var ReduxLoginProvider = require("../ReduxLoginProvider.bs.js");

function body(chapter) {
  return "Top Agent Network's " + chapter + " chapter has been put on hold as we focus our resources on our more active chapters across the country.\n      We appreciate your membership and will relaunch in $chapter when demand increases.";
}

function ClosedChapterOverlay$LoggedInDialog(props) {
  var logout = props.logout;
  var match = Intercom.useIntercom();
  var show = match.show;
  return JsxRuntime.jsxs(Dialog__Jsx3.make, {
              title: "Your chapter has been put on hold",
              isOpen: true,
              ariaLabel: "Your chapter has been put on hold",
              onDismiss: (function (prim) {
                  
                }),
              showCloseButton: false,
              children: [
                JsxRuntime.jsx(Text__Jsx3.make, {
                      lineHeight: {
                        NAME: "float",
                        VAL: 1.5
                      },
                      children: body(props.chapter)
                    }),
                JsxRuntime.jsx(View__Jsx3.make, {
                      mt: "md"
                    }),
                JsxRuntime.jsxs(ViewStack.make, {
                      justifyContent: "flexEnd",
                      flexDirection: "row",
                      children: [
                        JsxRuntime.jsx(Buttons__Jsx3.SecondaryButton.make, {
                              onClick: (function (param) {
                                  show();
                                }),
                              children: "Contact support"
                            }),
                        JsxRuntime.jsx(Buttons__Jsx3.PrimaryButton.make, {
                              onClick: (function (param) {
                                  logout();
                                }),
                              children: "Log out"
                            })
                      ]
                    })
              ]
            });
}

var LoggedInDialog = {
  make: ClosedChapterOverlay$LoggedInDialog
};

function ClosedChapterOverlay(props) {
  var currentUser = CurrentUser.useCurrentSession();
  var logout = ReduxLoginProvider.useLogout();
  var recordEvent = EventLogs.useRecordEventByCurrentUser();
  var logout$1 = function () {
    recordEvent(undefined, "Logout", undefined);
    logout();
  };
  if (typeof currentUser !== "object") {
    return null;
  }
  if (currentUser.TAG !== "Agent") {
    return null;
  }
  var match = currentUser._0.chapter;
  var displayName = match.displayName;
  if (displayName !== undefined && Types__Chapter.isChapterClosed(Caml_format.int_of_string(match.id))) {
    return JsxRuntime.jsx(ClosedChapterOverlay$LoggedInDialog, {
                logout: (function () {
                    logout$1();
                  }),
                chapter: displayName
              });
  } else {
    return null;
  }
}

var make = ClosedChapterOverlay;

var $$default = ClosedChapterOverlay;

exports.body = body;
exports.LoggedInDialog = LoggedInDialog;
exports.make = make;
exports.default = $$default;
exports.__esModule = true;
/* Intercom Not a pure module */
