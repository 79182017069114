// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ReactSpring = require("react-spring");

function toJsObj(a, b) {
  return Object.assign(b, a);
}

function constructSpringObj(values, from, config) {
  if (config !== undefined) {
    var c = Caml_option.valFromOption(config);
    if (from === undefined) {
      return function () {
        return Object.assign({
                    config: c
                  }, values());
      };
    }
    var f = Caml_option.valFromOption(from);
    return function () {
      return Object.assign({
                  from: f,
                  config: c
                }, values());
    };
  }
  if (from === undefined) {
    return function () {
      return Object.assign({}, values());
    };
  }
  var f$1 = Caml_option.valFromOption(from);
  return function () {
    return Object.assign({
                from: f$1
              }, values());
  };
}

function useSpring(values, from, config, param) {
  return ReactSpring.useSpring(constructSpringObj(values, from, config));
}

function MakeTransition(Config) {
  return {};
}

function useTrail(number, from, config, values, param) {
  return ReactSpring.useTrail(number, constructSpringObj(values, from, config));
}

function MakeSpring(Config) {
  var interpolate = function (prim0, prim1) {
    return prim0.interpolate(prim1);
  };
  var use = function (config, from, startValues) {
    var match = ReactSpring.useSpring(constructSpringObj((function () {
                return {
                        __values: startValues
                      };
              }), from, config));
    var set_ = match[1];
    var set = function (config, setValues) {
      var setInput = constructSpringObj((function () {
              return {
                      __values: setValues
                    };
            }), from, config);
      set_(setInput());
    };
    return [
            match[0].__values,
            set
          ];
  };
  return {
          interpolate: interpolate,
          use: use
        };
}

function MakeTrail(Config) {
  var interpolate = function (prim0, prim1) {
    return prim0.interpolate(prim1);
  };
  var use = function (number, config, from, startValues) {
    var match = ReactSpring.useTrail(number, constructSpringObj((function () {
                return {
                        __values: startValues()
                      };
              }), from, config));
    var set_ = match[1];
    var setFn = function (config, from, setValues) {
      var setInput = constructSpringObj((function () {
              return {
                      __values: setValues
                    };
            }), from, config);
      set_(setInput());
    };
    return [
            match[0].map(function (t) {
                  return t.__values;
                }),
            setFn
          ];
  };
  return {
          interpolate: interpolate,
          use: use
        };
}

function MakeSprings(Config) {
  var interpolate = function (prim0, prim1) {
    return prim0.interpolate(prim1);
  };
  var use = function (number, config, from, startValues) {
    var match = ReactSpring.useSprings(number, (function (i) {
            return constructSpringObj((function () {
                          return startValues(i);
                        }), from, config);
          }));
    var set_ = match[1];
    var setFn = function (config, from, setValues) {
      var setInput = function (i) {
        return constructSpringObj((function () {
                      return setValues(i);
                    }), from, config);
      };
      console.log("setInput", setInput, setInput(0));
      set_(setInput);
    };
    return [
            match[0].map(function (t) {
                  return t;
                }),
            setFn
          ];
  };
  return {
          interpolate: interpolate,
          use: use
        };
}

function Spring$Div(props) {
  var __children = props.children;
  var spreadProps = props.spreadProps;
  var children = __children !== undefined ? Caml_option.valFromOption(__children) : null;
  var props$1 = Object.assign(spreadProps !== undefined ? Caml_option.valFromOption(spreadProps)() : ({}), {
        className: props.className,
        onMouseMove: props.onMouseMove,
        onMouseLeave: props.onMouseLeave,
        onClick: props.onClick,
        onMouseDown: props.onMouseDown,
        style: props.style
      });
  return React.createElement(ReactSpring.animated.div, props$1, children);
}

var Div = {
  make: Spring$Div
};

exports.toJsObj = toJsObj;
exports.constructSpringObj = constructSpringObj;
exports.useSpring = useSpring;
exports.MakeTransition = MakeTransition;
exports.useTrail = useTrail;
exports.MakeSpring = MakeSpring;
exports.MakeTrail = MakeTrail;
exports.MakeSprings = MakeSprings;
exports.Div = Div;
/* react Not a pure module */
