// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var React = require("react");
var Spring = require("../../../../v5/common/bindings/Spring.bs.js");
var Icon__Jsx3 = require("../../atoms/Icon/Icon__Jsx3.bs.js");
var View__Jsx3 = require("../../helpers/View__Jsx3.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var PervasivesU = require("rescript/lib/js/pervasivesU.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var Theme__Color = require("../../../../reason/common/Theme/Theme__Color.bs.js");
var ReactSpring = require("react-spring");
var Heading__Jsx3 = require("../../helpers/Heading__Jsx3.bs.js");
var Touchable__Jsx3 = require("../../atoms/Touchable/Touchable__Jsx3.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Theme__Color__Vars = require("../../../../reason/common/Theme/Theme__Color__Vars.bs.js");
var StylesJs = require("../../../reason/molecules/Dialog/styles.js");

var make = StylesJs.AnimatedContent;

var JS = {
  make: make
};

function Dialog__Jsx3$AnimatedContent(props) {
  return JsxRuntime.jsx(make, {
              style: props.style,
              ariaLabel: props.ariaLabel,
              maxWidth: Caml_option.some(Core__Option.map(props.maxWidth, View__Jsx3.MinMaxWidthHeight.$$eval)),
              children: props.children
            });
}

var AnimatedContent = {
  JS: JS,
  make: Dialog__Jsx3$AnimatedContent
};

var make$1 = StylesJs.AnimatedUnstyledContent;

var AnimatedUnstyledContent = {
  make: make$1
};

var make$2 = StylesJs.AnimatedOverlay;

var AnimatedOverlay = {
  make: make$2
};

function useDialogControl() {
  var match = React.useState(function () {
        return false;
      });
  var setOpen = match[1];
  var isOpen = match[0];
  return [
          isOpen,
          (function () {
              setOpen(function (param) {
                    return !isOpen;
                  });
            })
        ];
}

var Transition = Spring.MakeTransition({});

var make$3 = StylesJs.TitleSection;

var JS$1 = {
  make: make$3
};

function Dialog__Jsx3$Overlay$TitleSection(props) {
  return JsxRuntime.jsx(make$3, {
              maxWidth: Caml_option.some(Core__Option.map(props.maxWidth, View__Jsx3.MinMaxWidthHeight.$$eval)),
              children: props.children
            });
}

var TitleSection = {
  JS: JS$1,
  make: Dialog__Jsx3$Overlay$TitleSection
};

function Dialog__Jsx3$Overlay$Title(props) {
  return JsxRuntime.jsx(Dialog__Jsx3$Overlay$TitleSection, {
              maxWidth: props.maxWidth,
              children: JsxRuntime.jsx(Heading__Jsx3.make, {
                    as_: "h2",
                    mb: {
                      NAME: "px",
                      VAL: 0
                    },
                    textAlign: "center",
                    color: Theme__Color.white,
                    children: props.title
                  })
            });
}

var Title = {
  make: Dialog__Jsx3$Overlay$Title
};

function Dialog__Jsx3$Overlay(props) {
  var children = props.children;
  var initialFocusRef = props.initialFocusRef;
  var __showCloseButton = props.showCloseButton;
  var maxWidth = props.maxWidth;
  var __customContent = props.customContent;
  var __onDismiss = props.onDismiss;
  var ariaLabel = props.ariaLabel;
  var title = props.title;
  var onDismiss = __onDismiss !== undefined ? __onDismiss : (function (prim) {
        
      });
  var customContent = __customContent !== undefined ? __customContent : false;
  var showCloseButton = __showCloseButton !== undefined ? __showCloseButton : true;
  var transitions = ReactSpring.useTransition([props.isOpen], (function (item) {
          return PervasivesU.string_of_bool(item);
        }), {
        from: {
          opacity: "0",
          transform: "translateY(-10px) scale(0.98)"
        },
        enter: {
          opacity: "1",
          transform: "translateY(0) scale(1)"
        },
        leave: {
          opacity: "0",
          transform: "translateY(20px) scale(1.1)"
        },
        config: {
          tension: 600,
          friction: 40
        }
      });
  return transitions.map(function (param) {
              if (!param.item) {
                return null;
              }
              var props = param.props;
              var style_opacity = props.opacity;
              var style_transform = props.transform;
              var style = {
                opacity: style_opacity,
                transform: style_transform
              };
              return JsxRuntime.jsxs(make$2, {
                          style: {
                            opacity: props.opacity
                          },
                          onDismiss: onDismiss,
                          initialFocusRef: initialFocusRef,
                          children: [
                            showCloseButton ? JsxRuntime.jsx(Touchable__Jsx3.make, {
                                    style: {
                                      hd: Css.top({
                                            NAME: "px",
                                            VAL: 0
                                          }),
                                      tl: {
                                        hd: Css.right({
                                              NAME: "px",
                                              VAL: 0
                                            }),
                                        tl: {
                                          hd: Css.cursor("pointer"),
                                          tl: {
                                            hd: Css.focus({
                                                  hd: Css.outlineStyle("none"),
                                                  tl: /* [] */0
                                                }),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    },
                                    position: "absolute",
                                    p: {
                                      NAME: "px",
                                      VAL: 20
                                    },
                                    onPress: (function (param) {
                                        onDismiss();
                                      }),
                                    children: JsxRuntime.jsx(Icon__Jsx3.make, {
                                          icon: "Cancel",
                                          size: 24,
                                          color: Theme__Color__Vars.white
                                        })
                                  }) : null,
                            customContent ? JsxRuntime.jsx(make$1, {
                                    style: style,
                                    ariaLabel: ariaLabel,
                                    children: children
                                  }) : JsxRuntime.jsxs(Dialog__Jsx3$AnimatedContent, {
                                    style: style,
                                    ariaLabel: ariaLabel,
                                    maxWidth: maxWidth,
                                    children: [
                                      title !== undefined ? JsxRuntime.jsx(Dialog__Jsx3$Overlay$Title, {
                                              maxWidth: maxWidth,
                                              title: title
                                            }) : null,
                                      children
                                    ]
                                  })
                          ]
                        }, param.key);
            });
}

var Overlay = {
  TitleSection: TitleSection,
  Title: Title,
  make: Dialog__Jsx3$Overlay
};

function Dialog__Jsx3(props) {
  var __showCloseButton = props.showCloseButton;
  var __maxWidth = props.maxWidth;
  var __customContent = props.customContent;
  var customContent = __customContent !== undefined ? __customContent : false;
  var maxWidth = __maxWidth !== undefined ? __maxWidth : ({
        NAME: "px",
        VAL: 400
      });
  var showCloseButton = __showCloseButton !== undefined ? __showCloseButton : true;
  return JsxRuntime.jsx(Dialog__Jsx3$Overlay, {
              title: props.title,
              ariaLabel: props.ariaLabel,
              isOpen: props.isOpen,
              onDismiss: props.onDismiss,
              customContent: customContent,
              maxWidth: maxWidth,
              showCloseButton: showCloseButton,
              initialFocusRef: props.initialFocusRef,
              children: props.children
            });
}

var make$4 = Dialog__Jsx3;

var dialog = Dialog__Jsx3;

exports.AnimatedContent = AnimatedContent;
exports.AnimatedUnstyledContent = AnimatedUnstyledContent;
exports.AnimatedOverlay = AnimatedOverlay;
exports.useDialogControl = useDialogControl;
exports.Transition = Transition;
exports.Overlay = Overlay;
exports.make = make$4;
exports.dialog = dialog;
/* make Not a pure module */
